export const createFormData = (data) => {
  const [
    name,
    email,
    company,
    position,
    message,
    reason] = data;
  let formData = new FormData();

  formData.append('name', name);
  formData.append('email', email);
  formData.append('company', company);
  formData.append('position', position);
  formData.append('message', message);
  formData.append('reason', reason);

  return formData;
}