import React, {useState} from 'react';
import axios from 'axios';
import { 
    Section, 
    Container
} from '../../GlobalStyles';
import Highlight from '../Highlight';
import ConfirmDialog from '../ConfirmDialog';
import {useAlert, ALERT_TYPES} from '@etiquette-ui/alert-dialog';
import { Input, Dropdown, TextArea } from '@etiquette-ui/inputs-dropdowns';
import { createFormData } from './utils';
import { isValidData, isValidEmail } from '../utils/forms';
import {
    Grid,
    Row,
    ContactContainer,
    InputContainer,
    FullInputContainer,
    Button,
    TextAreaContainer
} from './styles';
import useI18n from '../../i18n/useI18n';

const Contact = () => {
    const { translateObject, selectedLang } = useI18n();
    const alert = useAlert();

    const options = {
        options: [
            { label: `${translateObject({en: 'Doubts and comments', es: 'Dudas o comentarios'})}`, value: 0 },
            { label: `${translateObject({en: 'Contact a sales representantive', es: 'Contactar a un ejecutivo de ventas'})}`, value: 1 },
            { label: `${translateObject({en: 'Book a demo', es: 'Agendar una demostración'})}`, value: 2 },
        ],
        placeholder: `${translateObject({
            en: 'Pick one',
            es: 'Elige uno'
        })}`
    };

    const highlightTitle = {
        en: 'Con',
        es: 'Con'
    }

    const title = {
        en: 'tact',
        es: 'tacto'
    }

    const errorMessage = {
        en: 'This field is required.',
        es: 'Este dato es obligatorio.'
    }

    const emailErrorMessage = {
        en: 'Invalid email',
        es: 'Agrega un email válido'
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [message, setMessage] = useState('');
    const [reason, setReason] = useState('');
    const [isInvalidData, setIsInvalidData] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);

    const data = [
        name,
        email,
        company,
        position,
        message,
        reason
    ];

    const handleOnclick = (event) => {
        event.preventDefault();

        if(!!email && !isValidEmail(email)){
            setIsInvalidEmail(true);
            return;
        } 
        else setIsInvalidEmail(false);

        if(isValidData(data)){
            setIsInvalidData(false)
            let formData = createFormData(data);

            // try {
                // axios.post('https://some-api.com', formData, {
                // 'content-type': 'multipart/form-data',
                // });
                alert({
                    type: ALERT_TYPES.CUSTOM,
                    config: {
                        props: {                        
                            name: name,
                            lang: selectedLang,
                            translateObject: translateObject,
                        },
                        body: ConfirmDialog,
                    },
                }).then(()=>{
                    setName('');
                    setEmail('');
                    setCompany('');
                    setPosition('');
                    setMessage('');
                    setReason('');
                })
            // } catch (error) {
            //     console.error('Failed to submit files.');
            // }
        } else {
            setIsInvalidData(true)
        }
    }

    return (
        <Section color='white'>
            <Container>
                <Row>
                    <ContactContainer>
                        <h2 data-sal="slide-up" data-sal-easing="ease-in-circ"><Highlight text={translateObject(highlightTitle)}/>{translateObject(title)}</h2>
                        <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay='200'>
                            {translateObject({
                                en: `We want to hear from you, send us a
                                message and we will contact you as
                                soon as possible.`,
                                es: `Queremos saber de ti, envíanos un mensaje y te contactaremos pronto.`
                            })}
                            
                        </p>
                    </ContactContainer>
                    <InputContainer>
                        <Grid>
                            <Input
                                iconProps={{ icon: 'user' }}
                                labelProps={{ label: `${translateObject({
                                    en: 'Full name',
                                    es: 'Nombre completo'
                                })}`}}
                                inputProps={{
                                    value: name,
                                    required: true,
                                    onChange: (e)=> setName(e.target.value)
                                }}
                                errorProps={{
                                    error: `${isInvalidData && name === '' ? translateObject(errorMessage) : ''}`,
                                    style: {
                                        bottom: '-13px',
                                    }
                                }}
                            />
                            <Input
                                iconProps={{ icon: 'mail' }}
                                labelProps={{ label: `${translateObject({
                                    en: 'Email address',
                                    es: 'Email'
                                })}`}}
                                inputProps={{
                                    value: email,
                                    required: true,
                                    pattern:"[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$",
                                    onChange: (e)=> setEmail(e.target.value)
                                }}
                                errorProps={{
                                    error: `${isInvalidData && email === '' ? translateObject(errorMessage) : ''}${isInvalidEmail && !!email ? translateObject(emailErrorMessage) : ''}`,
                                    style: {
                                        bottom: '-13px',
                                    }
                                }}
                            />
                            <Input
                                iconProps={{ icon: 'pos' }}
                                labelProps={{ label: `${translateObject({
                                    en: 'Name of company',
                                    es: 'Nombre de la empresa'
                                })}`}}
                                inputProps={{
                                    value: company,
                                    required: true,
                                    onChange: (e)=> setCompany(e.target.value)
                                }}
                                errorProps={{
                                    error: `${isInvalidData && company === '' ? translateObject(errorMessage) : ''}`,
                                    style: {
                                        bottom: '-13px',
                                    }
                                }}
                            />
                            <Input
                                iconProps={{ icon: 'position' }}
                                labelProps={{ label: `${translateObject({
                                    en: 'Your position',
                                    es: '¿Cuál es tu cargo?'
                                })}`}}
                                inputProps={{
                                    value: position,
                                    required: true,
                                    onChange: (e)=> setPosition(e.target.value)
                                }}
                                errorProps={{
                                    error: `${isInvalidData && position === '' ? translateObject(errorMessage) : ''}`,
                                    style: {
                                        bottom: '-13px',
                                    }
                                }}
                            />
                        </Grid>

                        <FullInputContainer>
                            <Dropdown
                                labelProps={{ label: `${translateObject({
                                    en: 'Reason',
                                    es: 'Razón'
                                })}`}}
                                leadingIconProps={{ icon: 'list' }}
                                selectProps={{
                                    onChange: (option)=>setReason(option.label),
                                    ...options 
                                }}
                                errorProps={{
                                    error: `${isInvalidData && reason === '' ? translateObject(errorMessage) : ''}`,
                                    style: {
                                        bottom: '-16px',
                                    }
                                }}
                            />
                            <TextAreaContainer>
                                <TextArea
                                    labelProps={{ label: `${translateObject({
                                        en: 'Message',
                                        es: 'Mensaje'
                                    })}`}}
                                    inputProps={{
                                        value: message,
                                        required: true,
                                        rows: 4,
                                        onChange: (e)=> setMessage(e.target.value),
                                        style: {
                                            minHeight: 'auto'
                                        }
                                    }}
                                    errorProps={{
                                        error: `${isInvalidData && message === '' ? translateObject(errorMessage) : ''}`,
                                        style: {
                                            bottom: '-4px',
                                            left: '10px'
                                        }
                                    }}
                                />  
                            </TextAreaContainer>
                            
                            <Button onClick={handleOnclick}>
                                {translateObject({
                                    en: 'Send Message',
                                    es: 'Enviar Mensaje'
                                })}
                            </Button>
                        </FullInputContainer>
                    </InputContainer>
                </Row>
                

                
            </Container>
        </Section >
    );
};
export default Contact;